import { createAction } from "redux-actions"
import customerApis from "../apis/customerApis"

// action type
export const SET_INFO = "@trams-logistics-customer/customer/SET_INFO"
export const SET_BRANCHS = "@trams-logistics-customer/customer/SET_BRANCHS"
export const SET_POUCH_TRACKS = "@trams-logistics-customer/customer/SET_POUCH_TRACKS"
export const SET_POUCH_TRACKS_TOTAL = "@trams-logistics-customer/customer/SET_POUCH_TRACKS_TOTAL"
export const SET_FREIGHT_TRACKS = "@trams-logistics-customer/customer/SET_FREIGHT_TRACKS"
export const SET_FREIGHT_TRACKS_TOTAL = "@trams-logistics-customer/customer/SET_FREIGHT_TRACKS_TOTAL"
export const SET_POINTS = "@trams-logistics-customer/customer/SET_POINTS"
export const SET_POINT = "@trams-logistics-customer/customer/SET_POINT"
export const SET_FREIGHTS = "@trams-logistics-customer/customer/SET_FREIGHTS"
export const SET_FREIGHTS_TOTAL = "@trams-logistics-customer/customer/SET_FREIGHTS_TOTAL"
export const SET_FREIGHT = "@trams-logistics-customer/customer/SET_FREIGHT"
export const INIT_FREIGHT = "@trams-logistics-customer/customer/INIT_FREIGHT"
export const SET_DOCUMENTS = "@trams-logistics-customer/customer/SET_DOCUMENTS"
export const SET_DOCUMENTS_TOTAL = "@trams-logistics-customer/customer/SET_DOCUMENTS_TOTAL"
export const SET_DOCUMENT = "@trams-logistics-customer/customer/SET_DOCUMENT"
export const INIT_DOCUMENT = "@trams-logistics-customer/customer/INIT_DOCUMENT"
export const SET_DRIVERS = "@trams-logistics-customer/customer/SET_DRIVERS"
export const SET_DOCUMENT_PRINT = "@trams-logistics-customer/customer/SET_DOCUMENT_PRINT"
export const SET_FREIGHT_PRINT = "@trams-logistics-customer/customer/SET_FREIGHT_PRINT"
export const INIT_DOCUMENT_PRINT = "@trams-logistics-customer/customer/INIT_DOCUMENT_PRINT"
export const INIT_FREIGHT_PRINT = "@trams-logistics-customer/customer/INIT_FREIGHT_PRINT"
// action function
export const setInfo = createAction(SET_INFO)
export const setBranchs = createAction(SET_BRANCHS)
export const setPouchTracks = createAction(SET_POUCH_TRACKS)
export const setPouchTracksTotal = createAction(SET_POUCH_TRACKS_TOTAL)
export const setFreightTracks = createAction(SET_FREIGHT_TRACKS)
export const setFreightTracksTotal = createAction(SET_FREIGHT_TRACKS_TOTAL)
export const setDocumentsTotal = createAction(SET_DOCUMENTS_TOTAL)
export const setFreightsTotal = createAction(SET_FREIGHTS_TOTAL)
export const setPoints = createAction(SET_POINTS)
export const setPoint = createAction(SET_POINT)
export const setFreights = createAction(SET_FREIGHTS)
export const setFreight = createAction(SET_FREIGHT)
export const initFreight = createAction(INIT_FREIGHT)
export const setDocuments = createAction(SET_DOCUMENTS)
export const setDocument = createAction(SET_DOCUMENT)
export const initDocument = createAction(INIT_DOCUMENT)
export const setDirvers = createAction(SET_DRIVERS)
export const setDocumentPrint = createAction(SET_DOCUMENT_PRINT)
export const setFreightPrint = createAction(SET_FREIGHT_PRINT)
export const initDocumentPrint = createAction(INIT_DOCUMENT_PRINT)
export const initFreightPrint = createAction(INIT_FREIGHT_PRINT)
// action api function

// 로그인한 지점 상세
export const getInfo = () => async (dispatch) => {
  const result = await customerApis.getInfo()
  if (result.code === 0) {
    dispatch(setInfo(result.data.info))
    return true
  } else return false
}

// 기사 리스트
export const getDrivers = () => async (dispatch) => {
  const result = await customerApis.getDrivers()
  if (result.code === 0) {
    dispatch(setDirvers(result.data.drivers.filter((i) => i.isOkcheon === "0")))
    return true
  } else return false
}

// 지점 아이디 중복검사
export const getPointCheckId = (param) => async (dispatch) => {
  const result = await customerApis.getPointCheckId(param)
  if (result.code === 0) {
    return result.data.status
  } else return false
}

// 지사 리스트
export const getBranchList = (param) => async (dispatch) => {

  const result = await customerApis.getBranchs(param)
  
  if (result.code === 0) {

    let data = [{ branchSeq : 230, branchName : 'CJ' }].concat(result.data.branchs) 

    dispatch(setBranchs(data))

    return true

  } else return false
}

// 지사 리스트
export const getBranchs = (param) => async (dispatch) => {
  const result = await customerApis.getBranchs(param)
  if (result.code === 0) {

    // dispatch(setBranchs(result.data.branchs))

    let data = [{ branchSeq : 230, branchName : 'CJ' }].concat(result.data.branchs) 

    dispatch(setBranchs(data))

    return true
  } else return false
}
// 행낭 추적 리스트
export const getPouchTracks = (param) => async (dispatch) => {
  const result = await customerApis.getPouchTracks(param)
  if (result.code === 0) {
    dispatch(setPouchTracks(result.data.tracks))
    dispatch(setPouchTracksTotal(result.data.total))
    return true
  } else return false
}
// 소화물 추적 리스트
export const getFreightTracks = (param) => async (dispatch) => {
  const result = await customerApis.getFreightTracks(param)
  if (result.code === 0) {
    dispatch(setFreightTracks(result.data.tracks))
    dispatch(setFreightTracksTotal(result.data.total))
    return true
  } else return false
}
// 지점 리스트
export const getPoints = (param) => async (dispatch) => {
  const result = await customerApis.getPoints(param)
  if (result.code === 0) {
    dispatch(setPoints(result.data.points))
    return true
  } else return false
}
// 지점 상세
export const getPoint = (param) => async (dispatch) => {
  const result = await customerApis.getPoint(param)
  if (result.code === 0) {
    dispatch(setPoint(result.data))
    return true
  } else return false
}
// 지점 수정
export const patchPoint = (param) => async (dispatch) => {
  const result = await customerApis.patchPoint(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 소화물 리스트
export const getFreights = (param) => async (dispatch) => {
  const result = await customerApis.getFreights(param)
  if (result.code === 0) {
    dispatch(setFreights(result.data.freights))
    dispatch(setFreightsTotal(result.data.total))
    return true
  } else return false
}
// 소화물 상세
export const getFreight = (param) => async (dispatch) => {
  const result = await customerApis.getFreight(param)
  if (result.code === 0) {
    dispatch(setFreight(result.data.freight))
    return true
  } else return false
}
// 소화물 등록
export const postFreight = (param) => async (dispatch) => {
  const result = await customerApis.postFreight(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 소화물 수정
export const patchFreight = (param) => async (dispatch) => {
  const result = await customerApis.patchFreight(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 소화물 삭제
export const deleteFreight = (param) => async (dispatch) => {
  const result = await customerApis.deleteFreight(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 소화물 출력
export const printfreight = (param) => async (dispatch) => {
  const result = await customerApis.printfreight(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 문서 리스트
export const getDocuments = (param) => async (dispatch) => {
  const result = await customerApis.getDocuments(param)
  if (result.code === 0) {
    dispatch(setDocuments(result.data.documents))
    dispatch(setDocumentsTotal(result.data.total))
    return true
  } else return false
}
// 문서 상세
export const getDocument = (param) => async (dispatch) => {
  const result = await customerApis.getDocument(param)
  if (result.code === 0) {
    dispatch(setDocument(result.data.document))
    return true
  } else return false
}
// 문서 등록
export const postDocument = (param) => async (dispatch) => {
  const result = await customerApis.postDocument(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 문서 수정
export const patchDocument = (param) => async (dispatch) => {
  const result = await customerApis.patchDocument(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 문서 삭제
export const deleteDocument = (param) => async (dispatch) => {
  const result = await customerApis.deleteDocument(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 문서 출력
export const printDocument = (param) => async (dispatch) => {
  const result = await customerApis.printDocument(param)
  if (result.code === 0) {
    return true
  } else return false
}

//접수>문서 엑셀저장
export const getReceiptDocuDownloadExcel = (param) => async () => {
  const result = await customerApis.getReceiptDocuDownloadExcel(param)
  return result
}

//접수>소화물 엑셀저장
export const getReceiptFreightDownloadExcel = (param) => async () => {
  const result = await customerApis.getReceiptFreightDownloadExcel(param)
  return result
}

//행낭관리 엑셀저장
export const getPouchDownloadExcel = (param) => async () => {
  const result = await customerApis.getPouchDownloadExcel(param)
  return result
}

//소화물관리 엑셀저장
export const getFreightDownloadExcel = (param) => async () => {
  const result = await customerApis.getFreightDownloadExcel(param)
  return result
}

// 발신지점,수신지점 => 발신지사,수신지사
export const getPointToBranch = (param) => async () => {
  const result = await customerApis.getPointToBranch(param)
  return result.data
}

//문서 엑셀등록
export const postDocumentsUpload = (param) => async () => {
  const result = await customerApis.postDocumentsUpload(param)
  return result
}

//소화물 엑셀등록
export const postFreightsUpload = (param) => async () => {
  const result = await customerApis.postFreightsUpload(param)
  return result
}
