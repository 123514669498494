import { combineReducers } from "redux"
import { penderReducer } from "redux-pender"

import ConfigReducer from "./config"
import CustomerReducer from "./customer"

export default combineReducers({
  pender: penderReducer,
  ConfigReducer,
  CustomerReducer,
})
