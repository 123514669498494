import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_BRANCHS,
  SET_POUCH_TRACKS,
  SET_POUCH_TRACKS_TOTAL,
  SET_FREIGHT_TRACKS,
  SET_FREIGHT_TRACKS_TOTAL,
  SET_POINTS,
  SET_POINT,
  SET_FREIGHTS,
  SET_FREIGHT,
  SET_DOCUMENTS,
  SET_DOCUMENT,
  SET_INFO,
  SET_DOCUMENTS_TOTAL,
  SET_FREIGHTS_TOTAL,
  INIT_DOCUMENT,
  INIT_FREIGHT,
  SET_DRIVERS,
  SET_DOCUMENT_PRINT,
  SET_FREIGHT_PRINT,
  INIT_DOCUMENT_PRINT,
  INIT_FREIGHT_PRINT,
} = actions.CustomerAction

// default state value
const initialState = {
  branchs: [],
  pouchTracks: [],
  freightTracks: [],
  points: [],
  point: {},
  freights: [],
  freight: {},
  documents: [],
  document: {},
  info: {},
  freightTracksTotal: 0,
  pouchTracksTotal: 0,
  documentsTotal: 0,
  freightsTotal: 0,
  drivers: [],
  documentPrint: [],
  freightPrint: [],
}

// handle action
export default handleActions(
  {
    [SET_DOCUMENT_PRINT]: (state, action) => ({
      ...state,
      documentPrint: action.payload,
    }),
    [SET_FREIGHT_PRINT]: (state, action) => ({
      ...state,
      freightPrint: action.payload,
    }),
    [INIT_DOCUMENT_PRINT]: (state, action) => ({
      ...state,
      documentPrint: [],
    }),
    [INIT_FREIGHT_PRINT]: (state, action) => ({
      ...state,
      freightPrint: [],
    }),

    [SET_DRIVERS]: (state, action) => ({
      ...state,
      drivers: action.payload,
    }),
    [INIT_DOCUMENT]: (state, action) => ({
      ...state,
      document: {},
    }),
    [INIT_FREIGHT]: (state, action) => ({
      ...state,
      freight: {},
    }),

    [SET_FREIGHTS_TOTAL]: (state, action) => ({
      ...state,
      freightsTotal: action.payload,
    }),
    [SET_INFO]: (state, action) => ({
      ...state,
      info: action.payload,
    }),
    [SET_BRANCHS]: (state, action) => ({
      ...state,
      branchs: action.payload,
    }),
    [SET_POUCH_TRACKS]: (state, action) => ({
      ...state,
      pouchTracks: action.payload,
    }),
    [SET_FREIGHT_TRACKS]: (state, action) => ({
      ...state,
      freightTracks: action.payload,
    }),
    [SET_POUCH_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      pouchTracksTotal: action.payload,
    }),
    [SET_FREIGHT_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      freightTracksTotal: action.payload,
    }),
    [SET_POINTS]: (state, action) => ({
      ...state,
      points: action.payload,
    }),
    [SET_POINT]: (state, action) => ({
      ...state,
      point: action.payload,
    }),
    [SET_FREIGHTS]: (state, action) => ({
      ...state,
      freights: action.payload,
    }),
    [SET_FREIGHT]: (state, action) => ({
      ...state,
      freight: action.payload,
    }),

    [SET_DOCUMENTS_TOTAL]: (state, action) => ({
      ...state,
      documentsTotal: action.payload,
    }),
    [SET_DOCUMENTS]: (state, action) => ({
      ...state,
      documents: action.payload,
    }),
    [SET_DOCUMENT]: (state, action) => ({
      ...state,
      document: action.payload,
    }),
  },
  initialState
)
