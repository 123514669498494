import api from "../utils/api"
import cookie from "react-cookies"

// 로그인 지사 상세
const getInfo = async () => {
  const result = await api.get(`/info`)
  return result.data
}

// 지사 아이디 중복검사
const getPointCheckId = async (data) => {
  const result = await api.get(`/point/check/id?id=${data.id}`)
  return result.data
}
// 지사 리스트
const getBranchs = async (data) => {
  const result = await api.get(`/branchs`)
  return result.data
}
// 행낭 추적 리스트
const getPouchTracks = async (data) => {
  let query = `?`
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/pouch/tracks${query}`)
  return result.data
}
// 소화물 추적 리스트
const getFreightTracks = async (data) => {
  let query = `?`
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freight/tracks${query}`)
  return result.data
}
// 지점 리스트
const getPoints = async (data) => {
  const result = await api.get(`/points`)
  return result.data
}
// 지점 상세
const getPoint = async (data) => {
  const result = await api.get(`/point?pointSeq=${data.pointSeq}`)
  return result.data
}
// 지점 등록
const patchPoint = async (data) => {
  const result = await api.patch(`/point`, { body: data })
  return result.data
}
// 소화물 리스트
const getFreights = async (data) => {
  let query = `?`
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freights${query}`)
  return result.data
}
// 소화물 상세
const getFreight = async (data) => {
  const result = await api.get(`/freight?freightSeq=${data.freightSeq}`)
  return result.data
}
// 소화물 등록
const postFreight = async (data) => {
  const result = await api.post(`/freight`, { body: data })
  return result.data
}
// 소화물 수정
const patchFreight = async (data) => {
  const result = await api.patch(`/freight`, { body: data })
  return result.data
}
// 소화물 삭제
const deleteFreight = async (data) => {
  const result = await api.delete(`/freight`, { body: data })
  return result.data
}
// 소화물 출력
const printFreight = async (data) => {
  const result = await api.patch(`/freight/print`, { body: data })
  return result.data
}
// 문서 리스트
const getDocuments = async (data) => {
  let query = `?`
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.isScan !== undefined) {
    query += `&isScan=${data.isScan}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/documents${query}`)
  return result.data
}
// 문서 상세
const getDocument = async (data) => {
  const result = await api.get(`/document?docSeq=${data.docSeq}`)
  return result.data
}
// 문서 등록
const postDocument = async (data) => {
  const result = await api.post(`/document`, { body: data })
  return result.data
}
// 문서 수정
const patchDocument = async (data) => {
  const result = await api.patch(`/document`, { body: data })
  return result.data
}
// 문서 삭제
const deleteDocument = async (data) => {
  const result = await api.delete(`/document`, { body: data })
  return result.data
}
// 문서 출력
const printDocument = async (data) => {
  const result = await api.patch(`/document/print`, { body: data })
  return result.data
}

// 기사 리스트
const getDrivers = async () => {
  const result = await api.get(`/drivers`)
  return result.data
}
//접수>문서 엑셀저장
const getReceiptDocuDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/documents/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//접수>소화물 엑셀저장
const getReceiptFreightDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freights/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//행낭관리 엑셀저장
const getPouchDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/pouch/tracks/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

//소화물관리, 엑셀저장
const getFreightDownloadExcel = async (data) => {
  let query = `?`
  if (data.startDate) {
    query += `startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freight/tracks/download${query}`, { options: { responseType: "blob" } })
  return result.data
}

// 발신지점,수신지점 => 발신지사,수신지사
const getPointToBranch = async (data) => {
  const result = await api.get(`/point/to/branch?sendPointSeq=${data.sendPointSeq}&recvPointSeq=${data.recvPointSeq}`)
  return result.data
}

// 문서 엑셀 등록
const postDocumentsUpload = async (data) => {
  const result = await api.post(`/documents/upload`, { body: data })
  return result.data
}

// 소화물 엑셀 등록
const postFreightsUpload = async (data) => {
  const result = await api.post(`/freights/upload`, { body: data })
  return result.data
}

export default {
  getInfo,
  getPointCheckId,
  getBranchs,
  getPouchTracks,
  getFreightTracks,
  getDrivers,
  getPoints,
  getPoint,
  patchPoint,
  getFreights,
  getFreight,
  postFreight,
  patchFreight,
  deleteFreight,
  printFreight,
  getDocuments,
  getDocument,
  postDocument,
  patchDocument,
  deleteDocument,
  printDocument,
  getReceiptDocuDownloadExcel,
  getReceiptFreightDownloadExcel,
  getPouchDownloadExcel,
  getFreightDownloadExcel,
  getPointToBranch,
  postDocumentsUpload,
  postFreightsUpload,
}
